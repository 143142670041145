import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 218.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,218.000000) scale(0.100000,-0.100000)">


<path d="M3 1090 c1 -864 5 -1090 15 -1090 18 0 18 1990 0 2008 -10 10 -10 15
2 22 13 8 13 13 2 31 -10 15 -11 24 -3 32 17 17 14 87 -4 87 -13 0 -15 -129
-12 -1090z"/>
<path d="M987 2001 c4 -9 9 -242 11 -519 l3 -503 47 7 c26 3 47 7 47 8 0 0 -2
84 -5 186 -5 175 -5 180 -13 95 -4 -49 -10 -119 -13 -155 -7 -84 -12 -100 -24
-80 -11 18 -20 712 -11 857 7 114 30 95 156 -132 77 -139 138 -238 162 -263 7
-7 13 -17 13 -21 0 -10 28 -11 60 -4 23 6 23 7 -9 12 -34 6 -40 15 -177 261
-78 140 -146 255 -150 256 -5 1 -31 4 -57 7 -42 4 -47 3 -40 -12z"/>
<path d="M1708 1918 c-13 -11 -164 -275 -251 -441 -11 -21 -26 -41 -34 -44
-11 -4 -11 -8 -2 -20 15 -17 37 15 144 217 101 190 136 247 149 247 6 0 18 2
26 4 13 4 15 -44 15 -385 0 -303 -3 -390 -12 -393 -10 -4 -13 69 -14 339 0
270 -3 339 -12 324 -7 -11 -28 -37 -49 -59 -23 -25 -59 -91 -100 -180 -34 -78
-74 -153 -88 -167 -14 -14 -32 -36 -41 -49 l-15 -24 -46 64 c-25 36 -77 125
-115 199 -77 149 -150 270 -164 270 -5 0 -9 -3 -9 -6 0 -3 54 -100 120 -216
66 -115 120 -212 120 -215 0 -3 -12 -19 -27 -37 l-26 -31 6 53 c7 61 -7 69
-29 17 -31 -76 -7 -175 57 -229 19 -16 70 -46 114 -66 139 -64 197 -155 165
-257 -15 -47 -64 -101 -109 -120 -43 -18 -35 -27 10 -11 48 18 72 37 103 82
21 31 26 50 26 99 0 104 -48 162 -190 225 -107 48 -160 104 -160 172 0 39 16
39 24 0 9 -47 84 -115 156 -141 33 -12 80 -37 103 -55 23 -18 43 -31 46 -29
10 11 -40 50 -109 85 -93 47 -156 106 -165 154 -7 34 0 76 13 76 4 0 24 -32
45 -70 20 -39 41 -70 45 -70 4 0 24 31 44 68 33 58 39 64 44 45 3 -13 10 -23
15 -23 20 0 79 29 79 39 0 17 -23 61 -32 61 -15 0 -8 -31 8 -37 15 -6 15 -8
-2 -20 -24 -18 -37 -16 -51 6 -11 17 -1 41 71 176 46 86 85 157 87 160 2 2 3
-138 1 -310 -3 -310 -3 -314 18 -325 11 -6 34 -10 51 -8 l30 3 2 433 2 432
-36 0 c-19 0 -42 -6 -51 -12z"/>
<path d="M1544 1865 c-8 -21 -13 -44 -10 -51 3 -8 0 -14 -7 -14 -8 0 -7 -4 3
-10 23 -15 62 34 58 73 -4 44 -26 45 -44 2z"/>
<path d="M1073 1779 c-16 -16 -16 -84 1 -214 l12 -100 7 100 c8 125 -2 232
-20 214z"/>
<path d="M1154 1475 c-14 -11 -15 -14 -4 -15 13 0 13 -1 0 -10 -8 -5 -10 -10
-4 -10 21 0 49 28 38 39 -7 8 -16 7 -30 -4z"/>
<path d="M1567 1283 c-12 -12 -7 -128 7 -145 7 -10 16 -18 20 -18 3 0 6 38 6
85 0 64 -3 85 -13 85 -8 0 -17 -3 -20 -7z"/>
<path d="M1140 1227 c0 -17 38 -68 48 -65 4 1 18 -4 30 -11 29 -15 40 -8 22
15 -26 30 -100 76 -100 61z"/>
<path d="M1373 1062 c83 -91 76 -219 -16 -302 l-32 -29 25 -9 c54 -21 132 95
131 196 0 69 -24 110 -81 140 -40 21 -43 21 -27 4z"/>
<path d="M1687 883 c-4 -3 -7 -15 -7 -26 0 -75 -77 -182 -152 -212 -91 -36
-217 -8 -278 62 -27 30 -41 84 -22 83 4 -1 19 -17 35 -37 15 -20 27 -31 27
-24 0 8 7 11 17 6 12 -4 7 5 -11 24 -16 16 -34 50 -40 74 l-11 45 -39 -16
c-36 -14 -37 -16 -31 -51 14 -77 78 -155 152 -186 50 -21 159 -19 208 4 46 21
121 98 139 144 13 32 30 117 23 117 -2 0 -7 -3 -10 -7z"/>
<path d="M1002 856 c-13 -29 -1 -56 47 -99 22 -20 41 -42 41 -49 0 -6 3 -9 6
-5 3 3 21 -3 40 -13 21 -12 36 -15 40 -9 7 12 -33 49 -53 49 -8 0 -12 4 -9 9
4 5 3 11 -2 13 -14 6 -49 62 -55 89 -9 35 -42 44 -55 15z"/>
<path d="M1330 690 c0 -11 48 -28 92 -33 31 -4 118 23 118 36 0 2 -26 -3 -58
-11 -52 -14 -63 -14 -105 0 -26 8 -47 12 -47 8z"/>
<path d="M558 548 c-10 -30 58 -108 96 -108 19 0 11 56 -13 90 -20 28 -76 40
-83 18z"/>
<path d="M860 545 c0 -8 6 -15 14 -15 21 0 26 -23 26 -132 0 -91 -2 -100 -20
-105 -39 -10 -19 -23 35 -23 42 0 55 3 55 15 0 9 -9 15 -22 15 -22 0 -23 4
-26 90 -2 50 1 90 5 90 4 0 27 -44 50 -98 24 -56 48 -98 57 -100 9 -2 16 2 16
10 0 7 15 53 34 101 l33 88 6 -57 c7 -69 -1 -114 -19 -114 -8 0 -14 -5 -14
-11 0 -8 18 -10 61 -5 34 3 73 6 86 6 25 0 29 24 5 26 -24 3 -26 4 -14 16 7 7
12 20 12 30 0 15 8 18 44 18 38 0 45 -3 53 -25 5 -16 12 -22 20 -17 10 6 4 29
-22 95 -19 48 -35 93 -35 102 0 9 -6 12 -15 9 -8 -3 -30 -55 -50 -120 l-36
-114 -37 1 c-20 1 -29 3 -19 6 15 4 17 18 17 104 0 92 1 99 20 99 21 0 24 -14
20 -90 -1 -27 1 -32 10 -23 14 13 24 143 12 143 -4 0 -13 -5 -20 -12 -7 -7
-12 -8 -12 -2 0 6 -16 10 -37 9 l-36 -3 -37 -98 c-20 -55 -41 -98 -45 -97 -9
2 -84 171 -85 191 0 8 -16 12 -45 12 -33 0 -45 -4 -45 -15z m453 -127 c-2 -5
-17 -8 -33 -8 -31 0 -31 0 -3 80 3 10 11 1 23 -25 9 -22 15 -43 13 -47z"/>
<path d="M1020 541 c-14 -27 3 -61 31 -61 17 0 20 5 17 37 -3 44 -30 57 -48
24z"/>
<path d="M1324 545 c-3 -8 1 -34 9 -57 l15 -43 1 34 c1 28 4 33 21 28 13 -3
20 0 20 9 0 8 5 14 10 14 6 0 10 -37 10 -91 0 -81 2 -90 18 -85 17 6 51 -9 42
-18 -3 -2 -38 -6 -78 -8 -39 -2 -72 -7 -72 -12 0 -5 32 -6 73 -2 57 6 76 12
89 29 21 26 21 27 8 47 -9 13 -12 13 -21 -2 -15 -27 -29 -22 -29 11 0 26 3 29
40 33 l40 4 0 -42 c0 -25 -5 -44 -12 -47 -7 -2 -10 -8 -6 -12 4 -4 55 -2 115
3 81 8 110 15 120 28 10 14 13 15 18 3 7 -19 43 -22 73 -7 15 8 22 20 22 40 0
27 -10 41 -58 76 -24 18 -22 52 3 52 17 0 47 -35 38 -45 -5 -4 -3 -5 4 -1 12
7 8 31 -10 54 -15 19 -44 14 -67 -10 l-20 -22 0 25 0 24 -178 0 c-136 0 -176
-3 -174 -13 1 -6 -2 -12 -8 -12 -5 0 -10 7 -10 15 0 19 -39 20 -46 0z m134 -2
c-22 -6 -23 -17 -3 -33 8 -7 15 -18 15 -26 0 -7 5 -16 11 -20 9 -5 10 6 7 40
-3 25 -4 46 -1 46 16 0 33 -34 33 -64 0 -36 0 -36 -44 -36 -44 0 -44 0 -51 40
-4 22 -14 44 -23 50 -13 7 -6 9 28 9 25 -1 37 -3 28 -6z m257 -3 c4 -6 -6 -10
-22 -10 -20 0 -24 -3 -14 -9 8 -5 21 -7 28 -4 11 4 14 -1 11 -23 -2 -23 -9
-30 -29 -32 -23 -3 -27 1 -33 33 -4 19 -4 40 -1 45 8 13 52 13 60 0z m-135
-100 c0 -83 -1 -90 -20 -90 -17 0 -20 7 -20 53 0 77 11 127 26 127 11 0 14
-20 14 -90z m60 5 c0 -77 -2 -85 -19 -85 -27 0 -34 30 -16 73 8 20 15 50 15
67 0 16 5 30 10 30 6 0 10 -35 10 -85z m156 -5 c32 -12 41 -37 21 -57 -22 -22
-43 -10 -65 36 -14 28 -16 43 -9 50 8 8 14 7 20 -4 5 -9 20 -20 33 -25z m-68
-26 c2 -12 -4 -29 -13 -38 -20 -19 -70 -23 -58 -4 4 7 6 26 4 41 -2 27 -1 28
31 25 25 -2 34 -8 36 -24z"/>
<path d="M1687 423 c-4 -3 -7 -15 -7 -25 0 -26 24 -17 28 10 3 21 -8 29 -21
15z"/>
<path d="M1840 541 c0 -5 5 -13 10 -16 6 -3 10 -35 10 -71 0 -36 4 -64 10 -64
6 0 10 28 10 65 0 36 4 65 10 65 5 0 9 -28 7 -65 -2 -55 0 -63 11 -48 6 9 12
38 12 64 0 25 -6 51 -12 56 -19 15 -68 25 -68 14z"/>
<path d="M1928 529 c14 -15 16 -87 2 -123 -7 -19 -17 -26 -35 -26 -15 0 -24
-4 -20 -10 11 -17 43 -11 59 12 10 14 16 44 16 80 0 36 4 58 11 58 13 0 8
-101 -6 -134 -8 -17 -5 -18 31 -12 21 5 42 10 46 12 4 3 7 37 5 77 l-2 72 -60
3 c-48 2 -58 1 -47 -9z m90 -31 c-5 -34 -38 -37 -38 -4 0 20 5 26 21 26 15 0
20 -5 17 -22z m-3 -74 c0 -19 -6 -30 -17 -32 -14 -3 -18 3 -18 26 0 16 3 32 7
36 14 13 28 -1 28 -30z"/>
<path d="M827 524 c-10 -11 -8 -57 3 -74 9 -13 11 -13 20 0 21 31 0 97 -23 74z"/>
<path d="M710 351 c0 -32 11 -51 30 -51 25 0 33 16 26 46 -8 31 -56 35 -56 5z"/>
<path d="M574 298 c-17 -27 -13 -39 14 -36 19 2 27 9 27 23 0 23 -29 32 -41
13z"/>
</g>
</svg>



		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
